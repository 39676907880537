<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title> Inquiry Details </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
         <v-row class="mt-2">
          <v-col cols="4" md="4" sm="4" class="py-0">
              <p class="font-weight-bold">Name</p>
          </v-col>
           <v-col cols="8" md="8" sm="8" class="py-0">
              <p>{{form_data.contact_name}}</p>
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="4" md="4" sm="4" class="py-0">
              <p class="font-weight-bold">Email Address</p>
          </v-col>
           <v-col cols="8" md="8" sm="8" class="py-0">
              <p>{{form_data.email}}</p>
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="4" md="4" sm="4" class="py-0">
              <p class="font-weight-bold">Company Name</p>
          </v-col>
           <v-col cols="8" md="8" sm="8" class="py-0">
              <p>{{form_data.company_name}}</p>
          </v-col>
        </v-row>

         <v-row>
          <v-col cols="4" md="4" sm="4" class="py-0">
              <p class="font-weight-bold">Contact</p>
          </v-col>
           <v-col cols="8" md="8" sm="8" class="py-0">
              <p>{{form_data.phone}}</p>
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="4" md="4" sm="4" class="py-0">
              <p class="font-weight-bold">Comments</p>
          </v-col>
           <v-col cols="8" md="8" sm="8" class="py-0">
              <p>{{form_data.comments ? form_data.comments : '-'}}</p>
          </v-col>
        </v-row>
         <v-row>
          <v-col cols="4" md="4" sm="4" class="py-0">
              <p class="font-weight-bold">Website</p>
          </v-col>
           <v-col cols="8" md="8" sm="8" class="py-0">
              <p>{{form_data.website ? form_data.website : '-'}}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Inquiry",
  mounted() {
    this.getInquiryDetails();
  },
  data() {
    return {
      form_data: {},
      data: {},
    };
  },
  methods: {
    getInquiryDetails() {
      let _self = this;
      this.$axios
        .get(`admin/inquiry/details/${this.$route.params.id}`)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            _self.form_data = res.data.data;
          } else {
            let errors = res.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>